import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import './InvoicePage.css';

function InvoicePage() {
  const [invoiceData, setInvoiceData] = useState(null);
  const printRef = useRef();

  const registrationId = window.location.pathname.split('/').pop();

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await axios.get(`https://api-traing-as.onrender.com/api/payment/registration/${registrationId}`);
        setInvoiceData(response.data);
      } catch (error) {
        console.error('Error fetching invoice data', error);
      }
    };

    fetchInvoiceData();
  }, [registrationId]);

  const handleDownload = () => {
    const input = printRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice.pdf');
    });
  };

  if (!invoiceData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper">
      <div className="text-end">
        <button onClick={handleDownload}>Download Invoice</button>
      </div>
      <div className="invoice-container" ref={printRef}>
        <header className="invoice-header">
          <h1>INVOICE</h1>
          <div className="company-info">
            <h2>Arrival Solution</h2>
            <p>Chanakya Nagar, Nashik 422007</p>
            <p>Email: info@arrivalsolutions.in</p>
          </div>
        </header>

        <section className="invoice-info">
          <div className="invoice-to">
            <h3>INVOICE TO:</h3>
            <p><strong>{invoiceData.name}</strong></p>
            <p><b>P:</b> {invoiceData.phone}</p>
            <p><b>E:</b> {invoiceData.email}</p>
            <p><b>A:</b> {invoiceData.address}</p>
          </div>
          <div className="invoice-details">
            <p><b>Invoice ID:</b> {invoiceData._id}</p>
            <p><b>Date:</b> {new Date().toLocaleDateString()}</p>
          </div>
        </section>

        <table className="invoice-table">
          <thead>
            <tr>
              <th>PRODUCTS</th>
              <th>QTY</th>
              <th>PRICE</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{invoiceData.course}</td>
              <td>1</td>
              <td>₹{invoiceData.price}</td>
              <td>₹{invoiceData.price}</td>
            </tr>
          </tbody>
        </table>

        <section className="payment-method">
          <h3>Payment Method:</h3>
          <p>Online (UPI/Card/Wallet)</p>
        </section>

        <footer className="invoice-footer">
          <p>Thank you for your purchase!</p>
          <p>Arrival Solution</p>
        </footer>
      </div>
    </div>
  );
}

export default InvoicePage;
