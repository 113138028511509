import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './layout/Navbar';
import Landing from './component/landing/Landing';
import Courses from './component/course/List';
import ProductDetails from './component/course/Details';
import RegistrationForm from './component/form/RegistrationForm';
import PaymentPage from './component/form/PaymentPage';
import Footer from './layout/Footer';
import PrivacyPolicy from './component/footer/Privacy';
import TermsAndConditions from './component/footer/Term';
import FAQ from './component/footer/Faq';
import RefundPolicy from './component/footer/Refund';
import InfoSection from './component/about/About';
import Features from './component/about/Feature';
import Testimonial from './component/about/OurLearner';
import ContactSection from './component/contact/ContactSection';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <div >
      <BrowserRouter>
        <Navbar />
        <ScrollToTop/>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/about-us' element={<InfoSection />} />
          <Route path='/course' element={<Courses />} />
          <Route path='/course-details/:id' element={<ProductDetails />} />
          <Route path='/features' element={<Features />} />
          <Route path='/our-learner' element={<Testimonial />} />
          <Route path='/contact-us' element={<ContactSection />} />
          <Route path="/enroll-now" element={<RegistrationForm />} />
          <Route path="/payment/:registrationId" element={<PaymentPage />} />


          {/*  */}
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/term-condition' element={<TermsAndConditions />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/refund-cancelation' element={<RefundPolicy />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
