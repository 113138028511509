import React, { useEffect, useState } from 'react';
import CourseList from './CourseList';

const Courses = () => {
 

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <h1>Our Popular Training </h1>
      <CourseList/>
    </div>
  );
};

export default Courses;
