import React from 'react';

const RefundCancellationPolicy = () => {
  return (
    <div className="bg-gray-50 p-8 sm:p-12 rounded-lg shadow-lg max-w-4xl mx-auto my-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Refund and Cancellation Policy
      </h1>

      <p className="text-gray-700 mb-6">
        Thank you for choosing Arrival Solution’s 8-week training program. This policy outlines our terms regarding refunds and cancellations. By enrolling in our program, you agree to the following conditions:
      </p>

      <section className="space-y-6 text-gray-700">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">1. No Refund Policy</h2>
          <p>
            Once payment has been made and enrollment is confirmed, no refunds will be issued under any circumstances. This applies to the full course fee, including any registration, processing, or administrative charges.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">2. Course Cancellation by Arrival Solution</h2>
          <p>
            In the rare event that Arrival Solution must cancel the training program due to unforeseen circumstances, participants will be notified promptly. In such cases, participants will receive a full refund of any fees paid, or they may choose to transfer to another program date if available.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">3. Participant Cancellation or Withdrawal</h2>
          <p>
            If a participant chooses to cancel their enrollment or withdraw from the program for personal reasons, no refund will be provided. This policy applies regardless of when the cancellation occurs before, during, or after the start of the program.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">4. Program Transfers</h2>
          <p>
            Requests to transfer enrollment to a future session may be considered on a case-by-case basis, subject to availability and approval by Arrival Solution. Additional fees may apply for such transfers.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">5. Exceptional Circumstances</h2>
          <p>
            In the case of exceptional, documented circumstances (such as serious illness or family emergencies), participants may contact Arrival Solution to discuss alternative options. Any accommodations are solely at the discretion of Arrival Solution and will not involve a refund.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">6. Contact Us</h2>
          <p>If you have any questions or concerns regarding this Refund and Cancellation Policy, please contact us:</p>
          <address className="mt-2 text-gray-800">
            Arrival Solution <br />
            Chanakya Nagar, Nashik 422007 <br />
            Email: <a href="mailto:info@arrivalsolutions.in" className="text-blue-600 underline">info@arrivalsolutions.in</a>
          </address>
        </div>
      </section>

      
    </div>
  );
};

export default RefundCancellationPolicy;
