import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function RegistrationForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    phone: '',
    email: '',
    gender: '',
    language: '',
    course: state?.courseName || '',
    price: state?.price || 0,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post('https://api-traing-as.onrender.com/api/payment/register', formData);
      const { orderId, registrationId } = data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: formData.price * 100,
        currency: 'INR',
        name: 'Arrival Solution',
        description: 'Course Payment',
        order_id: orderId,
        handler: async (response) => {
          const paymentData = { registrationId, paymentStatus: 'Done' };
          await axios.post('https://api-traing-as.onrender.com/api/payment/update-status', paymentData);
          navigate(`/payment/${registrationId}`);
        },
        prefill: {
          name: formData.name,
          email: formData.email,
          contact: formData.phone,
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error('Error processing payment', error);
      alert('Payment failed. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
          Register Now
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit}>
            {/* Name Field */}
            <div>
              <label htmlFor="name" className="block text-sm font-medium leading-5 text-gray-700">Name</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            {/* Address Field */}
            <div className="mt-6">
              <label htmlFor="address" className="block text-sm font-medium leading-5 text-gray-700">Address</label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="address"
                  name="address"
                  type="text"
                  value={formData.address}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            {/* Phone Field */}
            <div className="mt-6">
              <label htmlFor="phone" className="block text-sm font-medium leading-5 text-gray-700">Phone</label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            {/* Email Field */}
            <div className="mt-6">
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">Email address</label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            {/* Gender Field */}
            <div className="mt-6">
              <label htmlFor="gender" className="block text-sm font-medium leading-5 text-gray-700">Gender</label>
              <div className="mt-1">
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>

            {/* Language Field */}
            <div className="mt-6">
              <label htmlFor="language" className="block text-sm font-medium leading-5 text-gray-700">Language</label>
              <div className="mt-1">
                <select
                  id="language"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                >
                  <option value="">Select Learning Language</option>
                  <option value="Marathi">Marathi</option>
                  <option value="Hindi">Hindi</option>
                  <option value="English">English</option>
                </select>
              </div>
            </div>

            {/* Course Field (Read-only) */}
            <div className="mt-6">
              <label htmlFor="course" className="block text-sm font-medium leading-5 text-gray-700">Course</label>
              <input
                id="course"
                name="course"
                type="text"
                value={formData.course}
                readOnly
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>

            {/* Price Field (Read-only) */}
            <div className="mt-6">
              <label htmlFor="price" className="block text-sm font-medium leading-5 text-gray-700">Price</label>
              <input
                id="price"
                name="price"
                type="text"
                value={`₹${formData.price}`}
                readOnly
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-[#004AAD] transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>

            {/* Submit Button */}
            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#004AAD] hover:bg-blue-600 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  Register & Pay
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegistrationForm;
