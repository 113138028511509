import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('https://api-traing-as.onrender.com/api/course/view');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCourses(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
      <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-10">
        {courses.map((course, index) => (
          <Link to={`/course-details/${course._id}`} key={index}>
            <div className="rounded overflow-hidden shadow-lg">
              <div className="relative">
                <img className="w-full" src={course.coverImage} alt={course.altText || 'Course Image'} />
                <div className="hover:bg-transparent transition duration-300 absolute inset-0 bg-gray-900 opacity-25"></div>
                <div className="absolute bottom-0 left-0 bg-[#004AAD] px-4 py-2 text-white text-sm hover:bg-white hover:text-[#004AAD] transition duration-500 ease-in-out">
                  {course.category.name}
                </div>
              </div>
              <div className="px-6 py-4">
                <p className="font-semibold text-lg inline-block hover:text-[#004AAD] transition duration-500 ease-in-out">
                  {course.name}
                </p>
                <p className="text-gray-500 text-sm">{course.shortDescription}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CourseList;
