import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-50 p-8 sm:p-12 rounded-lg shadow-lg max-w-4xl mx-auto my-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Privacy Policy for Arrival Solution Training Program
      </h1>

      <p className="text-gray-700 mb-6">
        Arrival Solution ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you enroll in our 8-week training program. By participating in the program, you agree to the terms outlined below.
      </p>

      <section className="space-y-6 text-gray-700">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">1. Information We Collect</h2>
          <p>
            We collect information that is necessary to provide you with our training services and to comply with legal requirements. The information we collect includes:
          </p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li><strong>Personal Information</strong>: Your name, email address, phone number, date of birth, and other details provided during registration.</li>
            <li><strong>Educational and Professional Information</strong>: Your educational background and any relevant work experience.</li>
            <li><strong>Payment Information</strong>: Billing address and payment details necessary for enrollment (processed securely through third-party payment providers).</li>
            <li><strong>Usage Data</strong>: Information about your activity in our program (e.g., attendance, assignment submissions, assessments, and engagement with course materials).</li>
          </ul>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">2. How We Use Your Information</h2>
          <p>We use your information for the following purposes:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li><strong>Program Administration</strong>: To manage your enrollment, attendance, participation, and assessments.</li>
            <li><strong>Communication</strong>: To provide important updates, class schedules, feedback, and other relevant information about the program.</li>
            <li><strong>Improvement of Services</strong>: To analyze usage patterns and feedback to improve our training materials and delivery.</li>
            <li><strong>Compliance</strong>: To comply with legal obligations and to enforce our program policies.</li>
            <li><strong>Certification</strong>: To issue a certificate of completion upon meeting program requirements.</li>
          </ul>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">3. Data Sharing and Disclosure</h2>
          <p>We do not sell or share your personal information with third parties, except as necessary to provide the program or comply with legal requirements:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li><strong>Service Providers</strong>: We may share your data with trusted service providers who assist us in delivering our training program (e.g., payment processors, learning management platforms).</li>
            <li><strong>Legal Compliance</strong>: We may disclose your information to comply with applicable laws, regulations, or legal proceedings.</li>
            <li><strong>Business Transfers</strong>: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the successor organization.</li>
          </ul>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">4. Data Security</h2>
          <p>We implement commercially reasonable security measures to protect your personal information. However, please note that no electronic transmission or storage is 100% secure. We recommend using a secure network when accessing our services and safeguarding your login credentials.</p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">5. Data Retention</h2>
          <p>We retain your information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. Following the completion of the program, we will retain basic information for certification and record-keeping purposes unless otherwise requested.</p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">6. Your Rights</h2>
          <p>Depending on your location, you may have certain rights regarding your personal information, including:</p>
          <ul className="list-disc ml-6 mt-2 space-y-1">
            <li><strong>Access</strong>: You may request access to the personal information we hold about you.</li>
            <li><strong>Correction</strong>: You may request corrections to any inaccurate or incomplete information.</li>
            <li><strong>Deletion</strong>: You may request deletion of your personal information, subject to legal requirements and our data retention policy.</li>
            <li><strong>Objection to Processing</strong>: You may object to certain types of processing, such as direct marketing.</li>
          </ul>
          <p>To exercise any of these rights, please contact us at <a href="mailto:info@arrivalsolutions.in" className="text-blue-600 underline">info@arrivalsolutions.in</a>.</p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">7. Children’s Privacy</h2>
          <p>Our training program is not intended for individuals under the age of 18. We do not knowingly collect personal information from anyone under 18 years of age.</p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">8. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify participants of any significant changes by posting an updated version on our website and updating the effective date.</p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">9. Contact Us</h2>
          <p>If you have questions or concerns about this Privacy Policy or our data handling practices, please contact us at:</p>
          <address className="mt-2 text-gray-800">
            Arrival Solution <br />
            Chanakya Nagar, Nashik 422007 <br />
            Email: <a href="mailto:info@arrivalsolutions.in" className="text-blue-600 underline">info@arrivalsolutions.in</a>
          </address>
        </div>
      </section>

     
    </div>
  );
};

export default PrivacyPolicy;
