import React from 'react';

const Features = () => {
    const features = [
        { title: "Multilingual Learning", description: "Gain skills in your preferred language – Marathi, Hindi, or English – making the training accessible and comfortable." },
        { title: "Real-World Project Exposure", description: "Work on real industry projects to develop a hands-on understanding of in-demand skills." },
        { title: "Expert-Led Sessions", description: "Learn from experienced industry professionals who provide practical insights and mentorship." },
        { title: "Flexible Remote Learning", description: "Participate from anywhere, with live online sessions and recorded classes for flexible learning." },
        { title: "Personalized Feedback and Guidance", description: "Receive detailed feedback on your work to ensure continuous improvement and skill-building." },
        { title: "Collaborative Team Projects", description: "Develop teamwork skills by collaborating on projects, mirroring real-world workplace scenarios." },
        { title: "Weekly Assessments & Progress Tracking", description: "Track your learning journey with weekly assessments that reinforce concepts and practical skills." },
        { title: "Career Readiness and Job Support", description: "Prepare for career opportunities with resume building, mock interviews, and job placement assistance." }
    ];

    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    <div>
                        <h2 className="text-lg font-semibold leading-8 tracking-tight text-[#004AAD]">Our Features</h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Everything You Need for Success: An All-in-One Training Platform</p>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Experience comprehensive training with multilingual learning, real-world projects, expert sessions, and personalized support to prepare you for career success!
                        </p>
                    </div>
                    <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
                        {features.map((feature, index) => (
                            <div key={index} className="relative pl-9">
                                <dt className="font-semibold text-gray-900">
                                    <svg className="absolute top-1 left-0 h-5 w-5 text-[#004AAD]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                                    </svg>
                                    {feature.title}
                                </dt>
                                <dd className="mt-2">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
};

export default Features;
