import React from "react";
import HeroSection from "../hero/Hero";
import InfoSection from "../about/About";
import Features from "../about/Feature";
import Courses from "../course/List";
import CourseList from "../course/CourseList";

const Landing = () =>{
    return(
        <>
       <HeroSection/>
       <InfoSection/>
       <CourseList/>
       <Features/>
        </>
    )
}

export default Landing;