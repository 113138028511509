import React from 'react';
import { Link } from 'react-router-dom';

const HeroSection = () => {
    return (
        <div className="relative bg-gradient-to-r from-purple-600 to-[#004AAD] h-screen text-white overflow-hidden">
            <div className="absolute inset-0">
                <img
                    src="https://images.unsplash.com/photo-1522252234503-e356532cafd5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NzEyNjZ8MHwxfHNlYXJjaHw2fHxjb2RlfGVufDB8MHx8fDE2OTQwOTg0MTZ8MA&ixlib=rb-4.0.3&q=80&w=1080"
                    alt="Background"
                    className="object-cover object-center w-full h-full"
                />
                <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>

            <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
                <h1 className="text-5xl font-bold leading-tight mb-4">Unlock Your Career Potential with Real-World Project Training</h1>
                <p className="text-lg text-gray-300 mb-8">Hands-On Industry Experience to Bridge the Gap from Learning to Earning</p>
                <Link
                      to="/course"
                      className="bg-[#004AAD] text-white hover:bg-blue-600 py-2 px-6 rounded-full text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
                >
                    Get Started
                </Link>
            </div>
        </div>
    );
};

export default HeroSection;
