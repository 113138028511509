import React from 'react';
import about from './../../assets/about.webp';
import { Link } from 'react-router-dom';

const InfoSection = () => {
    return (
        <div className="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl lg:mt-12 xl:max-w-6xl">
            
            {/* Image Column */}
            <div className="w-full h-64 lg:w-1/2 lg:h-auto">
                <img
                    className="h-full w-full object-cover"
                    src={about}
                    alt="Winding mountain road"
                />
            </div>
            
            {/* Text Column */}
            <div className="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
                
                {/* Text Wrapper */}
                <div className="flex flex-col p-12 md:px-16">
                    <h2 className="text-2xl font-medium uppercase text-[#004AAD] lg:text-4xl">Empowering Careers through Industry-Focused Training</h2>
                    <p className="mt-4">
                        At Arrival Solutions, we specialize in bridging the skills gap with hands-on, project-based training. Our curriculum is meticulously designed to align with industry standards, providing practical, real-world experience in content development and more. We’re committed to equipping you with the knowledge, tools, and confidence needed to excel in today’s fast-paced professional landscape. Get career-ready with us!
                    </p>
                    
                    {/* Button Container */}
                    <div className="mt-8">
                        <Link
                           to="/course"
                            className="inline-block w-full text-center text-lg font-medium text-white bg-[#004AAD] border-solid border-2 border-[#004AAD] py-4 px-10 hover:bg-blue-800 hover:shadow-md md:w-48"
                        >
                           Get Started
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoSection;
