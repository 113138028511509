import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const ProductDetails = () => {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const res = await axios.get(`https://api-traing-as.onrender.com/api/course/view/${id}`);
        setCourse(res.data);
        setMainImage(res.data.coverImage);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCourse();
  }, [id]);

  const handleImageChange = (src) => {
    setMainImage(src);
  };

  if (!course) return <p>Loading...</p>;

  const handleEnrollNow = () => {
    if (course) {
      navigate('/enroll-now', { state: { courseName: course.name, price: course.price } });
    }
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-8">
            <img src={mainImage} alt={course.name} className="w-full h-auto rounded-lg shadow-md mb-4" />
            {/* <div className="flex gap-4 py-4 justify-center overflow-x-auto">
              {[course.coverImage, course.courseImage].map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Thumbnail ${index + 1}`}
                  className="size-16 sm:size-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                  onClick={() => handleImageChange(src)}
                />
              ))}
            </div> */}
          </div>

          <div className="w-full md:w-1/2 px-4">
            <h2 className="text-3xl font-bold mb-2">{course.name}</h2>
            <p className="text-gray-600 mb-4">Category: {course.category.name}</p>
            <div className="mb-4">
              <span className="text-2xl font-bold mr-2">₹ {course.price}</span>
            </div>
            {/* <div className="flex items-center mb-4">
              {[...Array(5)].map((_, i) => (
                <svg
                  key={i}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className={`size-6 ${i < course.rating ? 'text-yellow-500' : 'text-gray-300'}`}
                >
                  <path
                    fillRule="evenodd"
                    d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                    clipRule="evenodd"
                  />
                </svg>
              ))}
              <span className="ml-2 text-gray-600">{course.rating} ({course.ratingCount} reviews)</span>
            </div> */}
            <p className="text-gray-700 mb-6">{course.description}</p>

            {/* <div className="mb-6">
              <h3 className="text-lg font-semibold mb-2">Content:</h3>
              {course.content.map((section, index) => (
                <div key={index} className="mb-4">
                  <h4 className="font-bold">{section.title}</h4>
                  <p className="text-gray-600">{section.content}</p>
                </div>
              ))}
            </div> */}

            <div className="flex space-x-4 mb-6">
              <button
                className="bg-[#004AAD] flex gap-2 items-center text-white px-6 py-2 rounded-md hover:bg-[#003E88] focus:outline-none focus:ring-2 focus:ring-[#004AAD] focus:ring-offset-2"
                onClick={handleEnrollNow}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                </svg>
                Enroll Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
