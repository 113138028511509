/* global _waEmbed */
import React, { useEffect } from 'react';

const WhatsAppWidget = () => {
  useEffect(() => {
    // Add the WhatsApp widget script to the DOM
    const script = document.createElement('script');
    script.src = 'https://d2mpatx37cqexb.cloudfront.net/delightchat-whatsapp-widget/embeds/embed.min.js';
    script.async = true;
    document.body.appendChild(script);

    // Define the WhatsApp button settings
    const wa_btnSetting = {
      btnColor: "#16BE45",
      ctaText: "WhatsApp Us",
      cornerRadius: 40,
      marginBottom: "70",
      marginLeft: 20,
      marginRight: 20,
      btnPosition: "right",
      whatsAppNumber: "919322142526",
      welcomeMessage: "Hello!\n I’m interested in learning more about your 8-week remote industry-based training program. Could you please share details on the course structure, fees, and enrollment process?\n\n Thank you!",
      zIndex: 999999,
      btnColorScheme: "light",
    };

    // Initialize the WhatsApp widget after the script loads
    script.onload = () => {
      if (typeof _waEmbed === 'function') {
        _waEmbed(wa_btnSetting);
      }
    };

    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // Since it's a script-only component, no UI is needed here
};

export default WhatsAppWidget;
