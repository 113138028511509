// src/components/Testimonial.js
import React from 'react';

const testimonials = [
  {
    id: 1,
    name: "Komal Kale",
    title: "Full Stack Developer",
    feedback: "The course is designed very well so that even the people with non-technical background can understand it."
  },
  {
    id: 2,
    name: "Shraddha Chougule",
    title: "Full Stack Developer",
    feedback: "I learned so much and you made even the more complex topics interesting and easier to follow through real-world stories."
  },
  {
    id: 3,
    name: "Vishal",
    title: "Java Developer",
    feedback: "The team is skilled and creative."
  }
];

const Testimonial = () => {
    return (
        <section className=" py-12">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-extrabold text-gray-800 text-center mb-8">What Our Learners Say</h2>
                <div className="flex flex-wrap justify-center gap-8">
                    {testimonials.map(({ id, name, title, feedback }) => (
                        <div
                            key={id}
                            className="bg-white max-w-sm p-6 rounded-lg shadow-lg flex flex-col items-center text-center"
                        >
                            <h3 className="text-lg font-semibold text-gray-800">{name}</h3>
                            <p className="text-gray-500 text-sm">{title}</p>
                            <p className="text-gray-700 mt-4">{feedback}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
