import React, { useState } from 'react';

const faqs = [
  {
    question: "What is the duration of the Arrival Solution training program?",
    answer: "Our training program spans 8 weeks, covering a comprehensive curriculum designed to enhance your skills in your chosen field."
  },
  {
    question: "Are there any prerequisites for enrolling?",
    answer: "There are no strict prerequisites; however, basic knowledge in the relevant field may be beneficial for getting the most out of the program."
  },
  {
    question: "What is the refund policy for the training program?",
    answer: "We have a no-refund policy once enrollment is confirmed. Please review our Refund and Cancellation Policy for complete details."
  },
  {
    question: "Can I transfer to a future session if I cannot attend?",
    answer: "Requests to transfer enrollment to a future session are considered on a case-by-case basis, subject to availability and approval by Arrival Solution."
  },
  {
    question: "How can I contact Arrival Solution for additional support?",
    answer: "You can reach us at info@arrivalsolutions.in for any questions or additional support related to the training program."
  }
];

const FaqComponent = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-gray-50 p-8 sm:p-12 rounded-lg shadow-lg max-w-4xl mx-auto my-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Frequently Asked Questions</h1>
      
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md">
            <button
              onClick={() => toggleFaq(index)}
              className="flex justify-between items-center w-full text-left text-gray-800 font-semibold focus:outline-none"
            >
              <span>{faq.question}</span>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </button>

            {activeIndex === index && (
              <p className="mt-2 text-gray-700">
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqComponent;
