import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="bg-gray-50 p-8 sm:p-12 rounded-lg shadow-lg max-w-4xl mx-auto my-10">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">
        Arrival Solution Training Program - Terms and Conditions
      </h1>

      <p className="text-gray-700 mb-6">
        Welcome to the Arrival Solution Training Program! By enrolling in our 8-week training program, you agree to the following terms and conditions. Please read them carefully before proceeding with registration.
      </p>

      <section className="space-y-6 text-gray-700">
        <div>
          <h2 className="text-lg font-semibold text-gray-800">1. Training Program Overview</h2>
          <p>
            The training program is an 8-week course designed to equip participants with in-depth knowledge and hands-on experience in MERN stack development (or other specified areas). The program includes both theoretical sessions and practical assignments, requiring active participation and commitment.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">2. Eligibility</h2>
          <p>
            The program is open to all individuals who meet the prerequisites as specified in the course details (e.g., prior programming knowledge or any other specified requirements). Applicants must be at least 18 years of age to enroll.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">3. Enrollment and Fees</h2>
          <p>
            A non-refundable enrollment fee must be paid in full upon registration to secure a spot in the program. Payment methods and deadlines will be communicated at the time of registration. Failure to make timely payment may result in forfeiture of the spot.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">4. Program Schedule</h2>
          <p>
            Classes will be held according to the pre-defined schedule, which will be shared with participants before the program begins. Arrival Solution reserves the right to reschedule classes in case of unforeseen circumstances. Notice will be provided in such instances.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">5. Attendance and Participation</h2>
          <p>
            Regular attendance is mandatory for successful completion of the program. Participants are expected to maintain at least 80% attendance. Active participation in discussions, assignments, and assessments is required to fully benefit from the program.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">6. Assessment and Certification</h2>
          <p>
            Participants will be assessed through assignments, quizzes, and projects during the course. A certificate of completion will be awarded to those who meet all program requirements, including attendance and successful completion of assessments.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">7. Code of Conduct</h2>
          <p>
            All participants are expected to adhere to Arrival Solution’s code of conduct, respecting instructors and fellow participants. Misconduct, including plagiarism, disruptive behavior, or disrespectful conduct, may result in dismissal from the program without refund.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">8. Intellectual Property</h2>
          <p>
            All course materials, including but not limited to slides, notes, and recordings, are the property of Arrival Solution. Participants are not permitted to reproduce, distribute, or share course materials outside the program without prior written consent from Arrival Solution.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">9. Privacy and Data Protection</h2>
          <p>
            Arrival Solution is committed to protecting participants’ personal information. All data collected will be used solely for program-related purposes in compliance with data protection laws.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">10. Liability</h2>
          <p>
            Arrival Solution is not liable for any personal injury, loss, or damage to personal property incurred during the program. By enrolling, participants acknowledge that they participate at their own risk.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">11. Program Changes</h2>
          <p>
            Arrival Solution reserves the right to modify, suspend, or cancel the program, including the terms and conditions, at any time. Participants will be informed of any changes.
          </p>
        </div>

        <div>
          <h2 className="text-lg font-semibold text-gray-800">12. Acceptance of Terms</h2>
          <p>
            By enrolling in the Arrival Solution Training Program, participants agree to abide by these terms and conditions. Failure to comply with these terms may result in disciplinary action, including removal from the program without a refund.
          </p>
        </div>
      </section>

      
    </div>
  );
};

export default TermsAndConditions;
